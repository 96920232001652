import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~2],
		"/[locale=locale]": [3],
		"/[locale=locale]/account": [~4],
		"/[locale=locale]/account/auth-code-error": [5],
		"/[locale=locale]/account/data": [~6],
		"/[locale=locale]/account/profile": [~7],
		"/[locale=locale]/account/sign-in": [~8],
		"/[locale=locale]/account/sign-in/success": [9],
		"/[locale=locale]/account/usage": [~10],
		"/[locale=locale]/chats": [11],
		"/[locale=locale]/chats/create": [~15],
		"/[locale=locale]/chats/search": [~16],
		"/[locale=locale]/chats/shared/[chatId]": [~17],
		"/[locale=locale]/chats/[chatId]": [~12],
		"/[locale=locale]/chats/[chatId]/message-sets/[messageSetId]": [13],
		"/[locale=locale]/chats/[chatId]/message-sets/[messageSetId]/[messageId]": [14],
		"/[locale=locale]/dashboard": [~18],
		"/[locale=locale]/library": [~19],
		"/[locale=locale]/library/chat-templates": [20],
		"/[locale=locale]/library/chat-templates/[chatTemplateId]": [~21]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';